<template>
  <v-card
    class="elevation-0 mx-auto"
    outlined
    max-width="1024"
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="medium"
      >
        mdi-arrow-up-bold-circle-outline
      </v-icon>
      <b>{{ 'title.newUpdates'.translate() }}</b>
    </HeaderCard>

    <v-card-text>
      <v-form
        ref="form"
        autocomplete="off"
      >
        <v-container
          grid-list-xl
          class="pt-0"
        >
          <v-layout
            v-if="isLoading"
            wrap
            align-center
            justify-center
          >
            <v-progress-circular
              :size="55"
              indeterminate
              color="primary"
              class="mx-auto my-7"
            />
          </v-layout>

          <v-layout
            v-else
            wrap
            class="px-3 qy-0"
          >
            <v-flex xs12>
              <FieldText
                label="Título"
                v-model="updateTitle"
                :rows="1"
              />
            </v-flex>

            <v-flex
              xs12
              sm6
            >
              <FieldTipoAtualizacao
                label="Tipo"
                v-model="updateType"
              />
            </v-flex>

            <v-flex
              xs12
              sm6
            >
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    label="Data da Atualização"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="updateDate"
                  color="primary"
                  no-title
                  @input="menu = false"
                />
              </v-menu>
            </v-flex>

            <v-flex
              xs12
              class="pb-0"
            >
              <div ref="toolbarRef" />
            </v-flex>

            <v-flex
              xs12
              class="pt-0"
              ref="fieldEditor"
            >
              <div
                class="overflow-y-auto"
                :style="`height: 400px; border: 1px solid #dedede; border-top: 0;`"
              >
                <FieldEditor
                  v-if="toolbarRef"
                  :toolbar-ref="toolbarRef"
                  v-model="updateDescription"
                  :items="[
                    'heading',
                    '|',
                    'fontSize',
                    'fontFamily',
                    '|',
                    'fontColor',
                    'fontBackgroundColor',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    '|',
                    'alignment',
                    'numberedList',
                    'bulletedList',
                    'outdent',
                    'indent',
                    '|',
                    'link',
                    'blockQuote',
                    'removeFormat',
                    'undo',
                    'redo'
                  ]"
                />
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-divider
      v-if="!isLoading"
    />

    <v-card-actions
      v-if="!isLoading"
      style="padding-top: 0.7em; padding-bottom: 0.7em;"
    >
      <v-layout
        wrap
        justify-space-around
      >
        <v-btn
          color="blue darken-1"
          text
          @click="goBack"
        >
          Voltar
        </v-btn>
        <v-btn
          color="success"
          small
          @click="handleSave"
          depressed
          :disabled="missingArgs"
        >
          Salvar dados
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import FieldText from '@/components/fields/FieldText'
import FieldEditor from '@/components/fields/FieldEditor'
import FieldTipoAtualizacao from '@/components/fields/FieldTipoAtualizacao'
import HeaderCard from '@/components/HeaderCard'
import moment from 'moment-timezone'
import { mapState } from 'vuex'
import { ROUTE_UPDATES_NEW, STATUS_SAVED, STATUS_ERROR } from '@/constants'

export default {
  components: {
    FieldText,
    HeaderCard,
    FieldEditor,
    FieldTipoAtualizacao
  },

  data () {
    const today = moment().format('YYYY-MM-DD')
    return {
      updateType: 'updates',
      updateDate: today,
      updateTitle: '',
      updateDescription: '',
      isLoading: false,
      toolbarRef: null,
      menu: false
    }
  },

  mounted () {
    const vm = this
    vm.isLoading = vm.$route.name !== ROUTE_UPDATES_NEW
    vm.isLoading ? vm.getUpdatePage() : vm.showEditor()
  },

  computed: {
    ...mapState({
      update: ({ updates }) => updates.row,
      status: ({ updates }) => updates.status
    }),

    computedDateFormatted () {
      return this.formatDate(this.updateDate)
    },

    dateFormat () {
      return moment(this.updateDate).format('YYYY-MM-DD')
    },

    missingArgs () {
      return (
        !String(this.updateDescription || '').trim() ||
        !String(this.updateTitle || '').trim() ||
        !String(this.updateDate || '').trim()
      )
    }
  },

  methods: {
    goBack () {
      this.$router.go(-1)
    },

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    getUpdatePage () {
      const vm = this
      vm.$nextTick().then(() => vm.$store.dispatch('loadUpdate', vm.$route.params))
    },

    showEditor () {
      this.toolbarRef = this.$refs.toolbarRef
    },

    handleSave () {
      const vm = this
      vm.isLoading = true
      const payload = {
        type: vm.updateType,
        updateDate: vm.dateFormat,
        title: vm.updateTitle,
        description: vm.updateDescription
      }
      if (vm.$route.params?.id) payload.id = vm.$route.params.id
      vm.$store.dispatch('saveUpdate', payload)
    }
  },

  watch: {
    update: {
      deep: true,
      handler (update) {
        if (!update?.id) return
        const vm = this
        Object.assign(vm, {
          updateType: update.type,
          updateDate: update.updateDate.split('T')[0],
          updateTitle: update.title,
          updateDescription: update.description
        })
        vm.isLoading = false
        vm.$nextTick().then(() => vm.showEditor())
      }
    },

    status: {
      deep: true,
      handler (status) {
        const vm = this

        if (status === STATUS_ERROR) {
          vm.isLoading = false
        }

        if (status === STATUS_SAVED) {
          vm.goBack()
          vm.$notify({
            type: 'success',
            title: 'Sucesso!',
            text: 'Atualização cadastrada.',
            duration: 7000
          })
        }
      }
    }
  }
}

</script>

<template>
  <v-autocomplete
    :items="tipos"
    :label="i18n(label)"
    :outlined="outlined"
    :readonly="readonly"
    :clearable="clearable"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    :hide-details="hideDetails"
    v-model="tipo"
  />
</template>

<script>

export default {
  props: {
    icon: {},
    value: {},
    label: { default: 'field.tipo' },
    outlined: { default: false },
    readonly: { default: false },
    clearable: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    return {
      tipo: this.value || '',
      tipos: [
        { value: 'improvements', text: 'Melhorias' },
        { value: 'updates', text: 'Atualizações' }
      ]
    }
  },

  watch: {
    tipo (tipo) {
      const changed = tipo !== this.value
      if (changed) this.$emit('input', tipo)
    },

    value (value) {
      const changed = value !== this.tipo
      if (changed) this.tipo = value
    }
  }
}
</script>
